<template>
  <div class="main">
    <div class="topBar" >
      <div class="back" @click="back">
        <van-image
          width="9px"
          @click="back"
          height="15px"
          :src="require('@/assets/cls/arrow-l.png')"
        />
      </div>
      <div>{{$t("answerPage.miningNum")}}</div>
      <div>
        <p>100</p>
        <p>c</p>
      </div>
    </div>
    <div class="Boss_progress" @click="toProgressList">
      <div class="pointed_star_fa">
        <div class="pointed_star">
          <div class="poNum" v-if="progressList[0].percentage == 100">
            <span>+{{contract_address[0]}}</span>
          </div>
          <van-image
            v-if="progressList[0].percentage == 100"
            width="16px"
            height="16px"
            :src="require('@/assets/xxImage/index/wujiaoxing.png')"
          ></van-image>
          <van-image
            v-if="progressList[0].percentage != 100"
            width="16px"
            height="16px"
            :src="require('@/assets/xxImage/index/wujiaoxingheise.png')"
          ></van-image>
          <div><span>1</span></div>
        </div>
        <div class="pointed_star">
          <div class="poNum" v-if="progressList[3].percentage == 100">
            <span>+{{contract_address[1]}}</span>
          </div>
          <van-image
            v-if="progressList[3].percentage == 100"
            width="16px"
            height="16px"
            :src="require('@/assets/xxImage/index/wujiaoxing.png')"
          ></van-image>
          <van-image
            v-if="progressList[3].percentage != 100"
            width="16px"
            height="16px"
            :src="require('@/assets/xxImage/index/wujiaoxingheise.png')"
          ></van-image>
          <div><span>4</span></div>
        </div>
        <div class="pointed_star">
          <div class="poNum" v-if="progressList[6].percentage == 100">
            <span>+{{contract_address[2]}}</span>
          </div>
          <van-image
            v-if="progressList[6].percentage == 100"
            width="16px"
            height="16px"
            :src="require('@/assets/xxImage/index/wujiaoxing.png')"
          ></van-image>
          <van-image
            v-if="progressList[6].percentage != 100"
            width="16px"
            height="16px"
            :src="require('@/assets/xxImage/index/wujiaoxingheise.png')"
          ></van-image>
          <div><span>8</span></div>
        </div>
        <div class="pointed_star">
          <div class="poNum" v-if="progressList[9].percentage == 100">
            <span>+{{contract_address[3]}}</span>
          </div>
          <van-image
            v-if="progressList[9].percentage == 100"
            width="16px"
            height="16px"
            :src="require('@/assets/xxImage/index/wujiaoxing.png')"
          ></van-image>
          <van-image
            v-if="progressList[9].percentage != 100"
            width="16px"
            height="16px"
            :src="require('@/assets/xxImage/index/wujiaoxingheise.png')"
          ></van-image>
          <div><span>10</span></div>
        </div>
      </div>
      <van-progress
        v-for="(item, index) in progressList"
        :key="index"
        :show-pivot="false"
        :percentage="item.percentage"
      />
    </div>
    <div class="topic">
      <div class="topic_item">
        <div class="title">
          <span>{{ this.manyNum + 1 }}</span
          >/10
        </div>
        <div class="topic_text">{{ lang == 'zh'? topic.content: topic.content_en }}</div>
        <div class="option">
          <div
            v-for="(item, index) in answerOption"
            :key="index"
            :class="[
              'option_item',
              item.type == 2
                ? item.display
                  ? 'active'
                  : ''
                : active == index
                ? 'active'
                : '',
            ]"
            @click="toOption(index, item.opt, item)"
          >
            <svg-icon
              v-if="(active == index) | item.display"
              icon-class="yes"
              style="
                font-size: 15px;
                position: absolute;
                left: 10px;
                color: white;
              "
            ></svg-icon>
            {{ item.value }}
          </div>
        </div>
        <div class="btn">   
          <van-button @click="btns" :disabled='!this.select'>
            <span>{{$t("answerPage.miningSpeed")}}</span>
          </van-button>
        </div>
      </div>
    </div>
    <div class="showModel" v-if="showModel"></div>
    <div class="Correct_answer" v-if="ZCorrect_answer">
      <van-image
        :src="require('../assets/xxImage/index/yescg.png')"
        width="80px"
        height="80px"
      ></van-image>
      <p
        style="
          color: #67c23a;
          font-size: 15px;
          font-weight: bold;
          margin-top: 10px;
        "
      >
        {{$t("answerPage.toolbox")}}
      </p>
      <div class="btn" v-if="manyNum == 9">
        <van-button @click="Question">
          <span class="">{{$t("answerPage.AddDraft")}}</span>
        </van-button>
      </div>
      <div class="btn" v-else>
        <van-button @click="nexnQuestion">
          <span class="">{{$t("answerPage.Addmining")}}</span>
        </van-button>
      </div>
    </div>
    <div class="Correct_answer" v-if="Correct_answer">
      <van-image
        :src="require('../assets/xxImage/index/nocg.png')"
        width="80px"
        height="80px"
      ></van-image>
      <p
        style="
          color: #f56c6c;
          font-size: 15px;
          font-weight: bold;
          margin-top: 10px;
        "
      >
        {{$t("answerPage.card")}}
      </p>
      <p class="zqDa">{{$t("answerPage.Successfully")}}: <span style="color: #8563f6">{{zqServe}}</span></p>
      <!-- <div class="btn" v-if="manyNum == 9">
        <van-button @click="Question">
          <span class="">{{$t("answerPage.AddDraft")}}</span>
        </van-button>
      </div>
      <div class="btn" v-else>
        <van-button @click="nexnQuestion">
          <span class="">{{$t("answerPage.Addmining")}}</span>
        </van-button>
      </div> -->
      <div class="btn">
        <van-button @click="CnexnQuestion">
          <span class="">{{$t("answerPage.cxzd")}}</span>
        </van-button>
      </div>
    </div>
    <van-popup
      v-model="showRight"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <div class="topBar">
        <div class="back" @click="back">
          <van-image
            width="9px"
            height="15px"
            :src="require('@/assets/cls/arrow-l.png')"
          />
        </div>
        <div>{{$t("answerPage.miningNum")}}</div>
        <div>
          <p>100</p>
          <p>c</p>
        </div>
      </div>
      <div class="complete">
        <van-image
          :src="require('../assets/xxImage/index/yescg.png')"
          width="80px"
          height="80px"
        ></van-image>
        <p class="p1">{{$t("answerPage.redeem")}}</p>
        <p class="p2">{{$t("answerPage.income")}}: <span>{{num}}</span>{{$t("answerPage.cancellation")}}</p>
        <div class="getbi">
          <svg-icon
            icon-class="bi"
            style="font-size: 25px; color: white"
          ></svg-icon>
          <p class="p3">{{$t("answerPage.determine")}}：{{this.reward}} coins</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showRight: false, //控制完成弹框
      showModel: false, //回答正确弹窗
      num: 0,
      active: null,
      Correct_answer: false, //错误弹框
      ZCorrect_answer: false, //正确弹框
      progressList: [
        { percentage: 0 },
        { percentage: 0 },
        { percentage: 0 },
        { percentage: 0 },
        { percentage: 0 },
        { percentage: 0 },
        { percentage: 0 },
        { percentage: 0 },
        { percentage: 0 },
        { percentage: 0 },
      ],
      attributeList: [], //题目列表
      topic: "", //题目
      answerOption: [], //答案选项列表
      select: "", //选中选项
      zqServe: "", //正确答案
      manyNum: 0, //第几题
      reward:'',//答题奖励
      fale:false,
      contract_address:null,
    };
  },
  created() {
    this.$axios.get(this.$api.setting).then(res => {
      res.data.forEach((item) => {
        if (item.key == "rewards_answering_questions_number") {
          this.contract_address = item.value.split(',');
        }
      });
    })
    this.topicList();
    this._i18n.locale = this.$storage.get("lang"); //设置初始化语言
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  methods: {
    handleFun(list) {
      let mapList = null
      this.answerOption = [];
      this.topic = list;
      this.zqServe = this.topic.answer;
      //处理选项列表数据 中英切换
      if(this.lang == "zh"){
        mapList = JSON.parse(this.topic.option);
      }else{
        mapList = JSON.parse(this.topic.option_en);
      }
      mapList.forEach((item, index) => {
        //用for in取值
        for (let i in item) {
          this.answerOption.push({
            opt: i,
            value: item[i],
            type: list.type,
            display: false,
          });
        }
      });
    },
    //获取今日闯关答题题目列表
    topicList() {
      this.$axios.get(this.$api.breakinto, {}).then((res) => {
        this.attributeList = res.data;
        this.handleFun(this.attributeList[this.manyNum]);
      });
    },
    back() {
      this.$router.push('/answer')
    },
    //跳转答题页
    toAnswer() {
      this.$router.push("/answer");
    },
    //完成按钮
    Question() {
      this.Correct_answer = false;
      this.showModel = false;
      this.showRight = true;

      this.$axios.post(this.$api.answerQuestions,{level:this.num}).then(val => {
        this.$axios.get(this.$api.setting).then(res => {
          // this.reward = res.data[0].value
          if(this.num == 1){
            this.reward = this.contract_address[0]
          }else if(this.num == 4){
            this.reward =Number(this.contract_address[0]) +Number(this.contract_address[1]) 
          }else if(this.num == 7){
            this.reward = Number(this.contract_address[0]) +Number(this.contract_address[1]) + Number(this.contract_address[2]) 
          }else if(this.num == 10){
            this.reward =Number(this.contract_address[0])  + Number(this.contract_address[1])  + Number(this.contract_address[2])  + Number(this.contract_address[3]) 
          }
        })
      })
    },
    //重新作答按钮
    CnexnQuestion() {
      this.answerOption.forEach((item)=>{
        item.display = false
      })
      this.active = null;
      this.select = '';
      this.Correct_answer = false;
      this.ZCorrect_answer = false;
      this.showModel = false;
      // this.manyNum += 1;
      // this.handleFun(this.attributeList[this.manyNum]);
    },
    //下一题按钮
    nexnQuestion() {
      this.active = null;
      this.select = '';
      this.Correct_answer = false;
      this.ZCorrect_answer = false;
      this.showModel = false;
      this.manyNum += 1;
      this.handleFun(this.attributeList[this.manyNum]);
    },
    //提交按钮
    btns(key) { 
      this.select = this.select.split(',').sort().join(',')
      if (this.select == this.zqServe) {
        this.ZCorrect_answer = true;
        this.progressList[this.num].percentage = 100;
        this.num += 1;
      }
      if (this.select != this.zqServe) {
        this.Correct_answer = true;
      }
      this.showModel = true;
    },
    //选中
    toOption(val, option, item) {
      //type为2是 多选题
      if (item.type == 2) { 
        //多选题逻辑 选中和取消
        item.display = !item.display;
        if (this.select.indexOf(option) == -1) {
          this.select = this.select ? this.select +','+ option : option;
        }else{
          var items = null
          if(this.select.length == 1){ 
            items = this.select.split(option)
          }else if(this.select.indexOf(option) == 0){
            items = this.select.split(option + ',')
          }else{
            items = this.select.split(','+option)
          }
          this.select = items.join(""); 
        }
      } else {
        //单选题
        this.active = val;
        this.select = option;
      }
    },
    toProgressList() {},
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100vh;
  background: url("../assets/xxImage/index/datipageBg.png") no-repeat;
  background-size: 100% 100%;
}
.topBar {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0 10px;
  div:nth-of-type(1) {
    width: 85px;
    font-size: 15px;
    font-weight: bold;
  }
  div:nth-of-type(2) {
    font-size: 18px;
  }
  div:nth-of-type(3) {
    width: 81px;
    height: 31px;
    border: 1px solid #000;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p:nth-of-type(1) {
      width: 50%;
      text-align: right;
    }
    p:nth-of-type(2) {
      width: 31px;
      height: 31px;
      border: 1px solid #000;
      border-right: 0px;
      background: #8f93fb;
      border-radius: 50%;
      text-align: center;
      line-height: 28px;
      color: white;
    }
  }
}
.Boss_progress {
  position: relative;
  margin: 0 15px;
  margin-top: 42px;
  width: 92%;
  height: 10px;
  border: 1px solid #000;
  border-radius: 10px;
  background: #8f93fb;
  display: flex;
  align-items: center;
  /deep/ .van-progress {
    width: 10%;
    height: 100%;
    background: #8f93fb;
  }
  /deep/ .van-progress__portion {
    border-radius: 0px;
    background: linear-gradient(0deg, #0dccff, #4760ff) !important;
  }
  .pointed_star_fa {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: -7px;
    right: 0;
    bottom: 0;
    z-index: 11;
    .poNum {
      position: absolute;
      span {
        position: relative;
        top: -20px;
        left: -4px;
        color: #ea5f00;
        opacity: 0;
        animation: widget 4s ease;
      }
      @keyframes widget {
        0% {
          top: -25px;
          opacity: 0;
        }
        50% {
          top: -30px;
          opacity: 1;
        }
        100% {
          top: -35px;
          opacity: 0;
        }
      }
    }
    .pointed_star {
      padding-top: 19px;
      text-align: center;
    }
  }
}
.topic {
  width: 346px;
  // height: 330px;
  border-radius: 10px;
  border-bottom: 1px solid #000;
  background: #8f93fb;
  margin: 0 auto;
  margin-top: 30px;
  padding-bottom: 9px;
  .topic_item {
    width: 346px;
    // height: 321px;
    border: 1px solid #000;
    border-radius: 10px;
    background: white;
    padding: 20px 10px;
    .title {
      width: 53px;
      height: 17px;
      background: #e1dfe0;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      margin: 0 auto;
      span {
        color: #ff9600;
      }
    }
    .topic_text {
      width: 100%;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 16px;
    }
    .option {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .option_item {
        position: relative;
        width: 206px;
        // height: 34px;
        border: 1px solid #bebebe;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #666666;
        margin-top: 16px;
        padding: 10px 5px;
      }
    }
  }
}
.btn {
  button {
    margin-top: 26px;
    width: 150px;
    height: 35px;
    border: 1px solid #000;
    border-radius: 5px;
    background: #8f93fb;
    margin-left: 50%;
    transform: translateX(-50%);
    color: white;
  }
}
.active {
  background: #4586f5;
  color: white !important;
}
.Correct_answer {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 181px;
  height: 211px;
  border: 1px solid #000;
  border-radius: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  z-index: 11;
  .zqDa {
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    margin-top: 12px;
  }
}
.showModel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.complete {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .p1 {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #29db53;
    margin-top: 10px;
  }
  .p2 {
    margin-top: 10px;
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
  }
  .getbi {
    width: 125px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;
    border-radius: 5px;
    margin-top: 10px;
    .p3 {
      color: #8563f6;
      font-size: 10px;
      margin-top: 4px;
    }
  }
}
</style>